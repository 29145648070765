import axios from 'axios';
import {Buffer} from 'safe-buffer';
import {mPrefixToHex, convertToPip, toBuffer} from 'minterjs-util';
import {generateWallet, walletFromMnemonic, walletFromPrivateKey, walletFromExtendedPrivateKey, generateMnemonic, isValidMnemonic} from 'minterjs-wallet';
import config from './config';
import MinterSendTxData from '../src/tx-data/send';
import MinterTxDataMultisend from 'minterjs-tx/src/tx-data/send';
import MinterTxDataSell from 'minterjs-tx/src/tx-data/sell';
import MinterTxDataSellAll from 'minterjs-tx/src/tx-data/sell-all';
import MinterTxDataBuy from 'minterjs-tx/src/tx-data/buy';
import MinterTxDataCreateCoin from 'minterjs-tx/src/tx-data/create-coin';
import MinterTxDataDelegate from 'minterjs-tx/src/tx-data/delegate';
import MinterTxDataUnbond from 'minterjs-tx/src/tx-data/unbond';
import MinterTxDataRedeemCheck from 'minterjs-tx/src/tx-data/redeem-check';
import {Minter, issueCheck, RedeemCheckTxParams} from "minter-js-sdk";
//import MinterTx from '../src/index';
import MinterTx from 'minterjs-tx/src/index';
import MinterTxSignature from 'minterjs-tx/src/tx-signature';
import {TX_TYPE_SEND, TX_TYPE_SELL_COIN, TX_TYPE_SELL_ALL_COIN, TX_TYPE_BUY_COIN, TX_TYPE_CREATE_COIN, TX_TYPE_DECLARE_CANDIDACY, TX_TYPE_SET_CANDIDATE_ON, TX_TYPE_SET_CANDIDATE_OFF, TX_TYPE_DELEGATE, TX_TYPE_UNBOND, TX_TYPE_REDEEM_CHECK, TX_TYPE_CREATE_MULTISIG, TX_TYPE_MULTISEND, TX_TYPE_EDIT_CANDIDATE, txTypeList} from '../src/tx-types';
import {formatCoin} from 'minterjs-tx/src/helpers';
import {API_TYPE_EXPLORER, API_TYPE_NODE} from 'minter-js-sdk';

const ENV_TESTNET = 'mainnet';

const ENV_SETTINGS = {
    [ENV_TESTNET]: {
        nodeBaseUrl: 'https://monsternode.net/node',
        explorerBaseUrl: 'https://explorer.minter.network'
    },
};

// select environment
const CURRENT_ENV = ENV_TESTNET;
let ENV_DATA = ENV_SETTINGS[CURRENT_ENV];


const minterSDK = new Minter({apiType: API_TYPE_NODE, baseURL: ENV_DATA.nodeBaseUrl});


function getNonce(addr) {
    return axios.get(`${config.nodeUrl}/address?address=`+addr)
        .then((response) => Number(response.data.result.transaction_count) + 1);
}
function getGas() {
    return axios.get(`${config.nodeUrl}/min_gas_price`)
        .then((response) => Number(response.data.result));
}

function sndTx(u) {   
    return axios.get(u);
}

function txret(u,txresid,TXTP,FORM_DATA,addr) {
sndTx(u, {
    }).then((response) => {
        console.log('Tx send', response.data);
        if(txresid) { txresid.html('<a href="https://explorer.minter.network/transactions/Mt'+response.data.result.hash.toLowerCase()+'" style="text-decoration:underline" target="_blank">Mt'+response.data.result.hash.toLowerCase()+'</a>'); }
    }).catch((error) => {
    
        window.localSendError(TXTP,FORM_DATA,error.response.data.error,addr,txresid);
        
        //console.log(error.response.data.error);
        //console.log(FORM_DATA);
        if(txresid) { txresid.html('<span style="font-size:10px">'+JSON.stringify(error.response.data.error.tx_result)+'</span>'); }
    });
}


window.generateWallet = () => {
	const wallet = generateWallet();
	const W = {};
	 W.address = wallet.getAddressString();
	 W.mnemonic = wallet.getMnemonic();
	 W.private = wallet.getPrivateKeyString();
	 W.public = wallet.getPublicKeyString();
	 	
	 	console.log(W.address);

	 return W;
}


window.walletFromMnemonic = (mnemonic) => {
	const wallet = walletFromMnemonic(mnemonic);
	const W = {};
	 W.address = wallet.getAddressString();
	 W.mnemonic = wallet.getMnemonic();
	 W.private = wallet.getPrivateKeyString();
	 W.public = wallet.getPublicKeyString();
	 	
	 	console.log(W.address);

	 return W;
}

/*
1: {name: "send", number: 1, hex: "0x01"}
2: {name: "sell", number: 2, hex: "0x02"}
3: {name: "sell all", number: 3, hex: "0x03"}
4: {name: "buy", number: 4, hex: "0x04"}
5: {name: "create coin", number: 5, hex: "0x05"}
6: {name: "declare candidacy", number: 6, hex: "0x06"}
7: {name: "delegate", number: 7, hex: "0x07"}
8: {name: "unbond", number: 8, hex: "0x08"}
9: {name: "redeem check", number: 9, hex: "0x09"}
10: {name: "set candidate on", number: 10, hex: "0x0A"}
11: {name: "set candidate off", number: 11, hex: "0x0B"}
12: {name: "create multisig", number: 12, hex: "0x0C"}
13: {name: "multisend", number: 13, hex: "0x0D"}
14: {name: "edit candidate", number: 14, hex: "0x0E"}
*/

window.issueCheck = (addr,priv,pass,coin,value,dueBlock,txresid) => {
	getNonce(addr).then((nonce) => {
	
	const check = issueCheck({
    	privateKey: priv,
    	passPhrase: pass,
    	nonce: nonce, // must be unique for private key
    	chainId: 1,
    	coinSymbol: coin,
    	value: value,
    	dueBlock: dueBlock, // at this block number check will be expired
	});
	
	if(txresid) { txresid.html('<span style="font-size:10px">'+check+'</span>'); }
	
	});
}

window.reedemCheck = (addr,priv,pass,check,feecoin,txresid) => {
	getNonce(addr).then((nonce) => {
	
	const txParams = new RedeemCheckTxParams({
    	privateKey: priv,
    	chainId: 1,
    	check: check,
    	password: pass,
    	feeCoinSymbol: feecoin,
	});

	minterSDK.postTx(txParams).then((txHash) => {
        console.log(txHash);
        if(txresid) { txresid.html('<a href="https://explorer.minter.network/transactions/Mt'+txHash.toLowerCase()+'" style="text-decoration:underline" target="_blank">Mt'+txHash.toLowerCase()+'</a>'); }
    })
    .catch((error) => {
        txresid.html('<span style="font-size:10px">'+JSON.stringify(error.response.data.error)+'</span>');
    });
    
    });
	
}


window.signTransAD = (addr,priv,TXTP,FORM_DATA,txresid,sorc,nonceSet) => {

	var txType = '';
	var txData;
	var nonce = nonceSet;
	var gas = 1;
	
	for (var ttp in txTypeList) {		
		if(txTypeList[ttp]['name']==TXTP) {
			txType = txTypeList[ttp]['hex'];
			break;
		}
	}
	
	if(!sorc) { sorc='send'; }
	if(nonceSet && nonceSet!='') { nonce = nonceSet; }


    if(TXTP=="delegate") {
txData = new MinterTxDataDelegate({
   	pubKey: toBuffer(FORM_DATA.pubkey),
   	coin: formatCoin(FORM_DATA.coin),
   	stake: `0x${convertToPip(FORM_DATA.stake, 'hex')}`,
});
    }


    const txParams = {
        nonce: `0x${nonce.toString(16)}`,
        chainId: '0x01',
        gasPrice: `0x${gas.toString(16)}`,
        gasCoin: formatCoin(FORM_DATA.feecoin),
        type: txType,
        data: txData.serialize(),
        signatureType: '0x01'
    };

    if (FORM_DATA.payload) {
        txParams.payload = `0x${Buffer.from(FORM_DATA.payload, 'utf-8').toString('hex')}`;
    }
    
    const PRIVATE_KEY = new Buffer(priv, 'hex');

    const tx = new MinterTx(txParams);
    tx.signatureData = (new MinterTxSignature()).sign(tx.hash(false), PRIVATE_KEY).serialize();
    
    	window.signTX(addr,`0x`+tx.serialize().toString('hex'),nonce);

}




window.send = (addr,priv,TXTP,FORM_DATA,txresid,sorc,nonceSet) => {
getNonce(addr).then((nonce) => {
	
	getGas().then((gas) => {
	
	if(!sorc) { sorc='send'; }
	if(nonceSet && nonceSet!='') { nonce = nonceSet; }
			
	var txType = '';
	var txData;
	
	for (var ttp in txTypeList) {		
		if(txTypeList[ttp]['name']==TXTP) {
			txType = txTypeList[ttp]['hex'];
			break;
		}
	}
	
		
	if(TXTP=="send") {	
txData = new MinterSendTxData({
    to: mPrefixToHex(FORM_DATA.to),
    coin: formatCoin(FORM_DATA.coin),
    value: `0x${convertToPip(FORM_DATA.value, 'hex')}`,
});
    }
    
    if(TXTP=="delegate") {
txData = new MinterTxDataDelegate({
   	pubKey: toBuffer(FORM_DATA.pubkey),
   	coin: formatCoin(FORM_DATA.coin),
   	stake: `0x${convertToPip(FORM_DATA.stake, 'hex')}`,
});
    }
    
    if(TXTP=="unbond") {
txData = new MinterTxDataUnbond({
   pubKey: toBuffer(FORM_DATA.pubKey),
   coin: formatCoin(FORM_DATA.coin),
   stake: `0x${convertToPip(FORM_DATA.stake, 'hex')}`,
});
    }

    if(TXTP=="sell") {
txData = new MinterTxDataSell({
   coinToSell: formatCoin(FORM_DATA.coinToSell),
   valueToSell: `0x${convertToPip(FORM_DATA.valueToSell, 'hex')}`,
   coinToBuy: formatCoin(FORM_DATA.coinToBuy),
   minimumValueToBuy: `0x${convertToPip(FORM_DATA.minimumValueToBuy, 'hex')}`,
});
    }

    if(TXTP=="sell all") {
txData = new MinterTxDataSellAll({
   coinToSell: formatCoin(FORM_DATA.coinToSell),
   coinToBuy: formatCoin(FORM_DATA.coinToBuy),
});
    }

    if(TXTP=="buy") {
txData = new MinterTxDataBuy({
     coinToBuy: formatCoin(FORM_DATA.coinToBuy),
     valueToBuy: `0x${convertToPip(FORM_DATA.valueToBuy, 'hex')}`,
     coinToSell: formatCoin(FORM_DATA.coinToSell),
     maximumValueToSell: `0x${convertToPip(FORM_DATA.maximumValueToSell, 'hex')}`,
 });
    }

    if(TXTP=="create coin") {
txData = new MinterTxDataCreateCoin({
   name: FORM_DATA.name,
   symbol: formatCoin(FORM_DATA.symbol),
   initialAmount: `0x${convertToPip(FORM_DATA.initialAmount, 'hex')}`,
   initialReserve: `0x${convertToPip(FORM_DATA.initialReserve, 'hex')}`,
   constantReserveRatio: parseInt(FORM_DATA.constantReserveRatio),
});
    }
    
    const txParams = {
        nonce: `0x${nonce.toString(16)}`,
        chainId: '0x01',
        gasPrice: `0x${gas.toString(16)}`,
        gasCoin: formatCoin(FORM_DATA.feecoin),
        type: txType,
        data: txData.serialize(),
        signatureType: '0x01'
    };

    if (FORM_DATA.payload) {
        txParams.payload = `0x${Buffer.from(FORM_DATA.payload, 'utf-8').toString('hex')}`;
    }

    //console.log({txParams});
    //console.log({txData});
    
    const PRIVATE_KEY = new Buffer(priv, 'hex');

    const tx = new MinterTx(txParams);
    tx.signatureData = (new MinterTxSignature()).sign(tx.hash(false), PRIVATE_KEY).serialize();
    
    if(sorc=='send') {
    	txret(`${config.nodeUrl}/send_transaction?tx=0x`+tx.serialize().toString('hex'),txresid,TXTP,FORM_DATA,addr);
    }
    if(sorc=='create') {
    	window.signTX(addr,`0x`+tx.serialize().toString('hex'));
    }
        
    });
});
}
